<template>
  <AppModal v-model="model" :title="$t('ComponentProfileModal.TitleModal').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #right>
          <AppBackButton analytics-name="profile-modal-back" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>

    <template #figureTitle>
      <AppCardFigureTitle :title="$t('ComponentProfileModal.Title').value">
        <template #figure>
          <AppFigure>
            <AppIcon name=" ">
              <IconUserAvatarFilledAlt />
            </AppIcon>
          </AppFigure>
        </template>
      </AppCardFigureTitle>
    </template>

    <!--Name-->
    <AppDescriptionList>
      <AppDescriptionListItem analytics-name="profile-modal-name">
        <template #title>
          {{ $t('ComponentProfileModal.NameText').value }}
        </template>
        <template #content>
          {{ fullName }}
        </template>
      </AppDescriptionListItem>
    </AppDescriptionList>
    <!--DOB-->
    <AppDescriptionList>
      <AppDescriptionListItem analytics-name="profile-modal-dob">
        <template #title>
          {{ $t('ComponentProfileModal.DateOfBirthText').value }}
        </template>
        <template #content>
          {{ dateDOB }}
        </template>
      </AppDescriptionListItem>
    </AppDescriptionList>
    <!--Email-->
    <AppDescriptionList>
      <AppDescriptionListItem analytics-name="profile-modal-email">
        <template #title> {{ $t('ComponentProfileModal.EmailText').value }}</template>
        <template #content>
          {{ profile.emailAddress }}
        </template>
      </AppDescriptionListItem>
    </AppDescriptionList>
    <!--Phone-->
    <AppDescriptionList>
      <AppDescriptionListItem analytics-name="profile-modal-phone">
        <template #title>
          {{ $t('ComponentProfileModal.PhoneText').value }}
        </template>
        <template #action>
          <AppButton
            theme="icon"
            analytics-name="profile-modal-edit-phone"
            @click="$router.push({ name: 'AccountEditPhone' })"
          >
            <AppIcon name=" ">
              <IconEdit />
            </AppIcon>
          </AppButton>
        </template>
        <template #content>
          {{ phoneNumberDisplay }}
        </template>
      </AppDescriptionListItem>
    </AppDescriptionList>
    <!--Address-->
    <AppDescriptionList>
      <AppDescriptionListItem analytics-name="profile-modal-edit-address">
        <template #title>
          {{ $t('ComponentProfileModal.AddressText').value }}
        </template>
        <template #action>
          <AppButton
            theme="icon"
            analytics-name="profile-modal-edit-address"
            @click="$router.push({ name: 'AccountEditAddress' })"
          >
            <AppIcon name=" ">
              <IconEdit />
            </AppIcon>
          </AppButton>
        </template>
        <template #content>
          {{ profile.address }}<br />
          {{ profile.city }},
          {{ profile.state }}
          {{ profile.postalCode }}
        </template>
      </AppDescriptionListItem>
    </AppDescriptionList>
  </AppModal>
</template>

<script>
import { computed } from '@vue/composition-api'
import { IconEdit, IconUserAvatarFilledAlt } from '@oen.web.vue2/icons'

import {
  useVModel,
  AppButton,
  AppBackButton,
  AppCardFigureTitle,
  AppDescriptionList,
  AppDescriptionListItem,
  AppFigure,
  AppIcon,
  AppModal,
  AppModalHeader,
} from '@oen.web.vue2/ui'

import { useI18nStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'ProfileModal',
  emit: ['input'],
  components: {
    AppButton,
    AppBackButton,
    AppCardFigureTitle,
    AppDescriptionList,
    AppDescriptionListItem,
    AppFigure,
    AppIcon,
    AppModal,
    AppModalHeader,
    IconEdit,
    IconUserAvatarFilledAlt,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const { model } = useVModel(props, emit)

    const profile = computed(() => authStore.userProfile)

    const phoneNumberDisplay = computed(() => {
      const { homePhone } = profile.value
      return `+${homePhone.countryCode}${homePhone.number}`
    })

    const dateDOB = computed(() => {
      const { dateOfBirth, language } = profile.value
      const date = new Date(dateOfBirth)
      return new Intl.DateTimeFormat(language, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }).format(date)
    })

    const fullName = computed(() => {
      return `${profile.value.firstName} ${profile.value.lastName}`
    })

    const fullAddress = computed(() => {
      return `${profile.value.city},${profile.value.state} ${profile.value.postalCode}`
    })

    return {
      profile,
      model,
      phoneNumberDisplay,
      dateDOB,
      fullName,
      fullAddress,
      $t,
    }
  },
}
</script>

<style scoped></style>
